function App() {
  return (
    <div>
      《随鹿旅行APP个人隐私保护政策》
      <br/>发布日期：2024年12月18日

      <br/>1 总则

      <br/>&emsp;&emsp;随鹿（以下也称“我们”）深知个人信息对您的重要性，因此我们非常重视保护您的隐私和个人信息。我们将按照法律法规要求并通过《随鹿个人信息保护政策》（以下简称“本政策”）向您说明我们在您使用随鹿旅行APP的产品/服务时如何处理您的个人信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。
      <br/>&emsp;&emsp;特别提示本政策的适用范围
      <br/>（1）本政策适用于随鹿旅行APP提供的所有产品和服务。如我们的产品/服务中使用了随鹿旅行APP提供的产品/服务但未设立独立个人信息保护政策的，则该部分产品/服务同样适用于本政策。如我们的产品/服务已设立独立个人信息保护政策的，则独立的个人信息保护政策优先适用，该等独立的个人信息保护政策中未提及而本政策有约定的内容，适用本政策。
      <br/>（2）本政策不适用于随鹿旅行APP的产品/服务中包含的或链接至第三方提供的信息/服务（包括任何第三方应用、网站等），该等信息/服务由第三方负责运营，须遵循该第三方的个人信息保护政策或类似规定。


      <br/>2 定义

      <br/>（1）	随鹿旅行APP：包括名为“随鹿旅行APP”的客户端（包括移动应用、微信小程序或H5页面）以及域名为soroute.cn的网页，客户端适用的终端设备包括但不限于PC、平板电脑、手机、电视等。
      <br/>（2）	随鹿/我们：随鹿旅行APP的开发者和运营者杭州途图智能科技有限公司（注册地址为浙江省杭州市西湖区三墩镇申花路798号420室）。
      <br/>（3）	用户/您：访问或使用随鹿旅行APP所提供的产品/服务的自然人，包括注册用户及未注册用户。
      <br/>（4）	个人信息：以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息。
      <br/>（5）	敏感个人信息：一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。本政策中涉及的敏感个人信息包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。
      <br/>（6）	个人信息删除：在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。
      <br/>（7）	儿童：不满十四周岁的未成年人。
      <br/>（8）	未成年人：不满十八周岁的自然人为未成年人。十六周岁以上的未成年人，以自己的劳动收入为主要生活来源的，视为完全民事行为能力人。


      <br/>3 个人隐私保护政策

      <br/>3.1 我们如何收集和使用您的个人隐私或信息
      <br/>&emsp;&emsp;在您使用我们的产品/服务时，您需要/可以选择授权我们收集和使用个人信息的情形包括：
      <br/>（1）	为了向您提供我们产品/服务的基本功能，您需要授权我们收集、使用必要信息的情形。如您拒绝提供前述必要信息，您将无法正常使用我们的产品/服务；
      <br/>（2）	为了向您提供我们产品/服务的附加功能，您可以选择授权我们收集、使用信息的情形。如您拒绝提供前述信息，您将无法正常使用相关附加功能或无法实现我们拟达到的功能效果，但并不会影响您正常使用我们产品/服务的基本功能。
      <br/>&emsp;&emsp;请您注意，因我们向您提供的产品和服务种类众多，我们将根据您选择使用的具体产品/服务范围，遵循“合理、正当、必要”原则收集和使用您的个人信息。除此之外，您理解并同意，基于向您提供更好的产品和服务的目的，我们可能会不时推出新的或优化后的功能，可能增加或变更收集和使用个人信息的目的、范围和方式。对此，我们将通过更新本政策、弹窗或站内信等通知方式另行向您说明收集和使用对应信息的目的、范围和方式，并为您提供同意与否的选项，且在征得您的明示同意后收集、使用对应信息。在此过程中，如您有任何疑问、意见或建议，您可通过本政策“3.9”提供的联系方式与我们联系。
      <br/>3.1.1为您提供搜索、浏览功能
      <br/>&emsp;&emsp;通常情况下，您可以在同意隐私政策后且未登录状态或隐私浏览模式下在随鹿旅行APP上搜索和浏览各类产品。但请您理解，出于保护商业信息目的，酒店页面上的部分详细信息可能需要您注册账户并登录后才可以进行浏览。
      <br/>&emsp;&emsp;在您同意本政策后，为保障搜索、浏览功能的实现，我们会收集您在网页或APP输入的搜索关键字、您点击的页面或链接以便在随鹿旅行APP的服务端向您返回相应的页面或结果。此外，我们也会收集您的日志信息。
      <br/>&emsp;&emsp;请您注意，单独的日志信息或仅搜索关键词信息无法单独识别您的身份，不属于您的个人信息。只有当您的单独的日志信息或搜索关键词信息与您的其他信息相互结合使用并可以识别您的身份时，在结合使用期间，我们会将您的日志信息或搜索关键词信息作为您的个人信息，按照本政策对其进行处理与保护。

      <br/>3.1.2帮助您注册与管理随鹿账户
      <br/>3.1.2.1账户注册
      <br/>&emsp;&emsp;若您想使用随鹿旅行APP的产品/服务，您首先需要注册一个随鹿账户成为随鹿旅行APP的会员。注册时，您至少需提供手机号（用于接收短信验证码）并设置密码用以创建随鹿账户。如您拒绝提供手机号，您将无法在随鹿旅行APP创建账户并使用随鹿旅行APP的产品/服务，但不影响您正常使用随鹿旅行APP的搜索、浏览功能。
      <br/>&emsp;&emsp;您理解并同意，您使用随鹿账户登录后在随鹿旅行APP的一切行为均代表您本人。您的随鹿账户操作所产生的电子信息记录均为您行为的有效凭据，并由您本人承担由此产生的全部责任。
      <br/>3.1.2.2 账户登录
      <br/>&emsp;&emsp;若您选择通过第三方账户（包括微信、QQ、Appe、支付宝、微博、百度等）登录随鹿旅行APP，在您同意本政策后，我们将从第三方获取您授权共享的第三方账户信息（头像、昵称及您在页面授权的其他个人信息），并将您的第三方账户与您的随鹿账户绑定，使您可以通过第三方账户直接登录并使用我们的服务。我们会依据与第三方的约定，在符合相关法规的前提下，使用您的个人信息。
      <br/>&emsp;&emsp;请您注意，在您首次使用第三方账户登录随鹿旅行APP（包括解绑第三方账户后的首次登录）的情形下，为确保符合法律法规的要求，保护您的账户安全，我们仍会要求您提供手机号，以验证您的真实身份。同时，我们会根据您提供的手机号实现第三方账户与随鹿账户的绑定。您可以随时通过“设置”－{'>'}“账号与安全”绑定或者解绑您所关联的第三方账户，您的解绑不会影响您使用随鹿旅行APP的基本功能。
      <br/>3.1.2.3 资料维护与账户管理
      <br/>（1）	为维护您的个人资料，提升您的服务体验，您可以选择提供头像、社区昵称、真实姓名、性别、出生日期、地区信息。其中，为保证交易辨识度，您的头像、社区昵称将公开显示。
      <br/>（2）	为在使用随鹿旅行APP的产品/服务时便捷地填写相关信息，您可以选择添加常用信息，包括出行人、联系人、地址信息、报销凭证信息等。
      <br/>&emsp;&emsp;如您选择不提供这些信息，不会影响您使用随鹿旅行APP的基本功能。另外在您输入文本时，我们可能会读取您的剪切板，以实现粘贴等功能，请您注意如您将剪切板内容直接粘贴到APP搜索栏，我们可能会将您粘贴的内容作为搜索条件回传至服务器。

      <br/>3.1.3 为您提供收藏、关注与分享功能
      <br/>&emsp;&emsp;在您使用随鹿旅行APP的产品/服务过程中，您可以选择对感兴趣的产品/服务/游记/日程计划进行收藏、与您感兴趣的商家/品牌/其他会员建立关注关系，也可以通过随鹿旅行APP的功能组件向第三方进行信息分享，我们至少需要收集您在收藏、关注与分享过程中产生的日志信息，用于实现上述功能以及其他我们明确告知的目的。
      <br/>3.1.4 为您提供信息发布功能
      <br/>&emsp;&emsp;您在随鹿旅行APP上主动对路线规划、推荐日程进行点评或发布其他内容（包括社区笔记、评论）时，您可以主动选择所上传的信息（如点评、照片、视频、日期、地点或地理位置信息）。您的社区昵称、头像和发布内容将被公开显示。
      <br/>&emsp;&emsp;请注意，您上传、发布或共享的信息中可能会导致您或他人的个人信息甚至敏感个人信息被公开，请您谨慎地考虑是否在使用我们的服务时上传、发布和共享相关信息。
      <br/>3.1.5 为您提供客户服务与处理争议
      <br/>&emsp;&emsp;为保证您的账户安全，我们的电话客服和在线客服会使用您的会员资料和订单信息，以验证您的身份。当您主动要求我们提供与您订单相关的客户服务时，我们可能会查询您的相关订单信息以便给予您适当的帮助和处理。如您主动要求客服协助您修改有关信息（如配送地址、联系人或联系电话），您可能还需要提供上述信息外的其他信息以便完成修改。
      <br/>&emsp;&emsp;当您与我们联系时，我们可能会保存与您的通信/通话记录和内容，或您留下的联系方式及相关信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。此外，当您使用专车服务时，您与司机的通话可能被录音，我们可能会保存您与司机的通话记录和内容，以便处理未来可能的争议以及保障服务安全。上述录音在被保留法律规定的最短必要时间后会自动删除，除非由于合规要求或合法利益需要而保留。
      <br/>3.1.6 为您调用权限提供其他附加服务
      <br/>&emsp;&emsp;为向您提供个性化的产品/服务，提升用户体验，您可以基于您自主授权的移动设备操作系统的相关权限来使用我们的附加功能，如果您不开启这些权限或不提供这些权限收集的个人信息，您依然可以完成随鹿旅行APP上各类服务的预订和相关服务。请您注意这些权限可能因移动设备操作系统的不同而存在差异。
      <br/>&emsp;&emsp;请您注意，您开启任意权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任意权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
      <br/>&emsp;&emsp;您可通过访问随鹿终端设备权限列表查询随鹿旅行APP可能申请与使用的相关权限。

      <br/><br/>安卓系统应用权限列表
      <br/>●	权限名称——权限功能说明——使用场景或目的
      <br/>●	网络——访问网络连接；改变网络状态；改变WIFI状态；——APP网络连接的必要权限，确保随鹿旅行APP能够连接随鹿服务端，如用于从服务器获取数据（景点信息、行程规划信息等）；与其他用户分享行程；
      <br/>●	媒体文件访问——读取和写入设备相册中的内容——用于个人信息设置头像；用于上传您的照片、视频等来进行各类旅行订单的点评等信息发布功能
      <br/>●	读取手机/电话状态——获取IMEI和IMSI等设备识别信息以及本机号码——为实现信息推送，保障账户和交易安全、排障及广告功能；预订火车票时向12306提供基于该权限获取的信息以避免预订失败；
      <br/>●	位置——通过网络或者卫星对您的设备定位——自动为您选择您所在的城市从而节省您手工输入所在地的信息，自动为您推荐附近包括酒店、景点在内的相关旅行服务，自动计算包括预订酒店时您与酒店的距离、使用用车服务时您附近的车辆信息、您与司机的距离、您的行驶路线在内的信息来实现便捷的预订或提升您的人身安全，以及基于您所在景区的位置为您提供所在景区的语音导览功能（用户使用该功能时APP需要持续获取定位来播放附近景点的讲解），在金融服务中用于查看和获取当前所在区域的服务以及对风险情况进行评估
      <br/>●	外部存储——读取和写入设备内存和SD卡——更改头像、发送社区动态、保存图片到本地、清理缓存从而减少磁盘占用空间提升运行速度等
      <br/>●	相机——拍摄照片和录制视频——用于完成视频拍摄、拍照、旅拍（社区）、证件扫描、二维码扫描、直播及人脸识别认证功能
      <br/>●	麦克风——录制音频——用于完成客服语音咨询功能
      <br/>●	读取通讯录——获取通讯录信息——用于在订单填写页面快速选择您通讯录中的联系人
      <br/>●	读写日历——获取或修改已设置的日历信息——用于开启出行前的日历提醒，此外在您使用火车票定时抢票功能时，我们需要读取日历权限来确保在有多个同一时间点的定时抢票提醒的场景下不会重复写入日历中
      <br/>●	拨打电话——调用系统拨打电话权限——用于在APP内直接拨打电话，方便联系客服
      <br/>●	蓝牙——获取蓝牙名——用于预订火车票时向12306提供基于该权限获取的信息以避免预订失败
      <br/>●	NFC——实现NFC支付——用于在支持NFC功能的手机上开启该功能来实现NFC支付
      <br/>●	读取应用列表——读取手机中已安装的应用信息——下单支付环节和金融产品风险控制；分享行程到其他社交软件平台
      <br/>●	悬浮窗——悬浮在其他APP之上——退出APP后悬浮播放直播视频
      <br/>●	传感器——收集陀螺仪、手机方向、加速度数据——用于VR（虚拟现实）场景感应旋转、地图导航方向旋转、观看视频横竖屏切换及交易安全保障场景(点击查看收集的传感器详情)
      <br/>●	指纹识别——在移动设备上使用指纹识别功能进行身份验证——用于支付场景，当用户开通了设备指纹支付时会优先验证指纹来完成支付
      <br/>●	蓝牙——蓝牙外设——用于通过蓝牙连接的外设设备进行VOIP通话
      <br/>●	剪切板——读取剪切板中的特殊字符——用于部分项目中根据用户剪贴板内的口令，对该口令对应的活动进行弹窗

      <br/><br/>iOS系统应用权限列表
      <br/>●	权限名称——权限功能说明——使用场景或目的
      <br/>●	网络——访问网络连接；改变网络状态；改变WIFI状态；——APP网络连接的必要权限，确保随鹿旅行APP能够连接随鹿服务端，如用于从服务器获取数据（景点信息、行程规划信息等）；与其他用户分享行程；
      <br/>●	读取手机/电话状态——获取IMEI和IMSI等设备识别信息以及本机号码——为实现信息推送，保障账户和交易安全、排障及广告功能；预订火车票时向12306提供基于该权限获取的信息以避免预订失败；
      <br/>●	读取应用列表——读取手机中已安装的应用信息——下单支付环节和金融产品风险控制；分享行程到其他社交软件平台
      <br/>●	外部存储——读取和写入设备内存和SD卡——更改头像、发送社区动态、保存图片到本地、清理缓存从而减少磁盘占用空间提升运行速度等
      <br/>●	通知——接收通知——用于接收随鹿旅行APP发送的通知
      <br/>●	位置——通过网络或者卫星对您的设备定位——自动为您选择您所在的城市从而节省您手工输入所在地的信息，自动为您推荐附近包括酒店、景点在内的相关旅行服务，自动计算包括使用用车服务时附近的车辆信息、您与司机的距离、您的行驶路线在内的信息来实现便捷的预订或提升您的人身安全，以及基于您所在景区的位置为您提供所在景区的语音导览功能（用户使用该功能时APP需要持续获取定位来播放附近景点的讲解），在金融服务中用于查看和获取当前所在区域的服务以及对风险情况进行评估
      <br/>●	媒体文件访问——读取和写入设备相册中的内容——用于个人信息设置头像；用于上传您的照片、视频等来进行各类旅行订单的点评等信息发布功能
      <br/>●	相机——拍摄照片和录制视频——用于完成视频拍摄、拍照、社区发布、证件扫描、二维码扫描、直播及人脸识别认证功能
      <br/>●	麦克风——录制音频——用于完成客服语音咨询功能
      <br/>●	日历——获取或修改已设置的日历信息——用于开启出行前的日历提醒，此外在您使用火车票定时抢票功能时，我们需要读取日历权限来确保在有多个同一时间点的定时抢票提醒的场景下不会重复写入日历中
      <br/>●	面部ID——使用面部ID——在支持面部识别的IOS设备上实现基于面部ID的认证功能
      <br/>●	触控ID——使用触控ID——在支持触控ID的IOS设备上实现基于触控ID的认证功能
      <br/>●	语音转文字——将语音转化为文字——用于在支持语音转文字的场景实现将语音转为文字后实现文字输入功能
      <br/>●	广告识别符——获取广告识别符——获取IDFA、IDFV，用于广告推荐
      <br/>●	运动与健身——获取陀螺仪、手机方向、加速度数据——用于VR（虚拟现实）场景感应旋转、地图导航方向旋转、观看视频横竖屏切换及交易安全保障场景(点击查看收集的传感器详情)
      <br/>●	Siri权限——苹果智能语音助手——用于通过Siri实现快捷下单
      <br/>●	媒体库权限——访问媒体库——用于腾讯视频SDK编辑导出媒体库音乐
      <br/>●	剪切板——读取剪切板中的特殊字符——用于火车票助力项目中根据用户剪贴板内的口令，对该口令对应的活动进行弹窗

      <br/>3.1.7 改进随鹿旅行APP的产品/服务
      <br/>（1）	我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善随鹿旅行APP的内容和布局，为商业决策提供产品或服务支撑，以及改进我们的产品和服务。
      <br/>（2）	我们可能将您的订单数据用于数据分析，以便让我们能够了解您所在的位置、喜好和人口统计信息，或与其他来源（包括第三方）的数据相匹配，从而开发和改进我们的产品和服务。
      <br/>（3）	我们可能会通过您提供的联系方式邀请您参与市场/服务调查，并基于您参加市场调查所提供的信息，分析、衡量您对我们的产品、服务和网站的兴趣，回访、了解您的服务体验和感受，从而开发和改进我们的产品和服务。如您选择不参与市场调查并提供有关信息（以具体市场调查展示页面为准），不会影响您使用随鹿旅行APP的基本功能。
      <br/>3.1.8 收集和使用您的个人信息的特别说明
      <br/>（1）	若您提供的信息中含有其他用户的个人信息，例如，您通过随鹿旅行APP为他人订购产品/服务时需要提交实际订购人的个人信息，或您通过随鹿旅行APP主动上传、发布或共享的信息中涉及其他用户的个人信息的，在前述情形下，在向随鹿旅行APP提供这些个人信息之前，您须确保您已经取得本人的同意，并确保其已知晓并接受本政策。若其中涉及儿童个人信息的，您需要事先取得对应儿童监护人的同意。除非符合征得授权同意的例外情形（见本政策“3.1我们如何收集和使用您的个人信息”），否则我们将会按照法律法规及国家标准的要求，针对某些场景下的敏感个人信息的处理，通过合理的方式征得您的单独同意。具体单独同意方式，以具体的功能页面显示为准。
      <br/>（2）	若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，均会重新获得您的授权同意。
      <br/>（3）	我们可能从为您提供服务的关联公司、业务合作伙伴等第三方处获得其所收集的您的相关信息。例如，您通过我们关联公司、业务合作伙伴网站及其移动应用软件等预订时，您向其提供的预订信息可能会转交给我们，以便我们处理您的订单，确保您顺利预订。我们将依据与第三方的约定，在收集前要求第三方说明您的个人信息来源，并对这些个人信息来源的合法合规性进行确认，了解第三方所收集您的个人信息的授权同意范围。如果我们收集和使用您的信息的目的，超出了第三方已获得的授权范围，我们会自行或要求该第三方另行征得您的同意后再处理您的个人信息。
      <br/>（4）	征得授权同意的例外
      <br/>&emsp;&emsp;您充分知晓，根据法律法规要求，以下情形中我们收集、使用您的个人信息无需征得您的授权同意：
      <br/>●	为履行随鹿法定职责或者法定义务所必需；
      <br/>●	与国家安全、国防安全有关的；
      <br/>●	与公共安全、公共卫生、重大公共利益有关的；
      <br/>●	与犯罪侦查、起诉、审判和判决执行等有关的；
      <br/>●	为订立、履行您作为一方当事人的合同所必需；
      <br/>●	在紧急状况下，出于维护您或其他个人的生命健康和财产安全；
      <br/>●	所收集的个人信息是个人信息主体自行向社会公众公开的或其他已经合法公开的个人信息；
      <br/>●	从合法公开披露的信息中收集您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
      <br/>●	为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
      <br/>●	学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
      <br/>●	法律法规以及国家标准规定的其他情形。
      <br/>&emsp;&emsp;请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的使用无需另行向您通知并征得您的同意。

      <br/>3.2 我们如何使用Cookie和同类技术
      <br/>3.2.1 Cookie
      <br/>&emsp;&emsp;为了让您获得更轻松便捷的访问体验，您访问随鹿旅行APP或使用我们提供的服务时，我们可能会在您的设备终端或系统上存储名为Cookie的小型数据文件用来识别您的身份，这样可以帮您省去重复输入注册信息的步骤，帮您优化对广告的选择与互动及其帮助判断您的账户安全状态。您可以清除计算机或移动设备上保存的所有Cookie，您有权接受或拒绝Cookie，如果浏览器自动接受Cookie，您可以根据自己的需要修改浏览器的设置以拒绝Cookie。请注意，如果您选择拒绝Cookie，那么您可能无法更好地体验我们提供的服务。
      <br/>3.2.2 其他同类技术
      <br/>&emsp;&emsp;除Cookie外，为监测广告投放效果，我们还会在网站上使用像素标签技术，借助于像素标签，我们可以更好地了解我们投放的广告是否被用户浏览。

      <br/>3.3 我们如何共享、转移、委托处理和公开披露您的个人信息
      <br/>3.3.1 共享与提供
      <br/>3.3.1.1处理原则
      <br/>&emsp;&emsp;我们暂时不会向合作伙伴等第三方共享您的行为信息、账户信息、设备信息（包括IMEI、设备序列号、OAID、MEID, Android ID, IMSI, GUID, MAC地址.SIM卡序列号）、已安装APP信息、传感器信息、剪切板、位置信息以及通过请求您的存储权限获取的外置存储信息，以保障为您提供的服务顺利完成。如若有关管理部门要求，我们仅会出于合法、正当、必要、特定、诚信、明确的目的共享您的个人信息，并且只会共享法律监管或公权力部门所必要的个人信息。
      <br/>3.3.1.2单独同意
      <br/>&emsp;&emsp;除非符合征得授权同意的例外情形（见本政策“一、我们如何收集和使用您的个人信息”），否则我们将会按照法律法规及国家标准的要求，针对除前述情形之外的某些场景下的个人信息的共享与提供，采取合理的方式另外征得您的单独同意。
      <br/>3.3.2 第三方SDK及第三方监测
      <br/>&emsp;&emsp;为了您能够接收信息推送、在第三方平台登录及分享信息、使用第三方支付、使用地图服务、使用人脸识别认证功能、实现手机号一键登录等服务及功能，我们的应用中会嵌入授权合作伙伴的软件工具开发包（以下简称“SDK”）或类似其他应用程序，我们通过操作系统提供接口调用的方式使用这些SDK，这些第三方软件工具开发包（SDK）的名称、提供方、收集个人信息的目的及类别可通过访问第三方软件工具开发包（SDK）详情页查询。
      <br/>&emsp;&emsp;请您注意，以上链接中的开发者文档或个人信息保护政策/隐私政策页面由相应SDK开发/提供商制作并发布，相关SDK开发/提供商将有可能在法律允许的范围内，对链接以及链接网页内容进行变动或调整，请以相关SDK开发/提供商最新公布的链接及链接网页内容为准。由于部分SDK开发/提供商未提供在线文档，我们无法提供所有的第三方SDK的开发文档或对应的个人信息保护政策/隐私政策的链接。
      <br/>&emsp;&emsp;我们会对授权合作伙伴的SDK进行严格的安全技术检测和准入控制。我们的合作伙伴无权将所共享的您的个人信息用于任何其他用途，如要改变个人信息的处理目的，则其应再次征求您的授权同意。
      <br/>&emsp;&emsp;为实现广告监测目的，在涉及第三方监测的广告页面我们可能会应广告主的要求使用嵌入第三方代码来实现广告监测服务，可能涉及的第三方监测平台的名称、使用目的、信息类型可访问第三方广告监测平台详情页。
      <br/>3.3.3信息转移
      <br/>&emsp;&emsp;我们不会将您的个人信息转移给任何公司、组织和个人，但以下情况除外：
      <br/>（1）	事先获得您的明确同意或授权；
      <br/>（2）	根据适用的法律法规、法律程序的要求、强制性的行政或司法要求；
      <br/>（3）	在涉及合并、分立、收购、资产转让或类似的交易时，如涉及个人信息转移，我们会在正式进行信息转移前，向您告知接收信息公司、组织的名称和联系方式，并要求新的持有您个人信息的公司、组织继续受本政策的约束，继续履行原先由我们承担的个人信息处理义务。否则，我们将要求该公司、组织重新向您征求授权同意。
      <br/>3.3.4 委托处理
      <br/>&emsp;&emsp;为了提升信息处理效率，降低信息处理成本或提高信息处理准确性，我们可能会委托有专业技术能力的关联公司或随鹿以外的外部服务供应商处理您的个人信息。我们会与受托方签署委托处理协议，并要求受托方按照委托处理协议、本政策以及其他任何相关的保密和安全措施来处理个人信息。委托关系不生效、无效或被撤销或者终止时，我们会要求受托方返还、删除您的个人信息(包括但不限于因委托处理获取到的原始信息或副本、摘要等)，不得保留。未经您的授权同意，我们禁止受托方转委托他人处理您的个人信息。
      <br/>3.3.5 公开披露
      <br/>&emsp;&emsp;我们仅会在以下情形，公开披露您的个人信息：
      <br/>（1）	根据您的需求，在您单独同意的前提下，根据您认可的披露方式披露您所指定的个人信息；
      <br/>（2）	根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。

      <br/>3.4 我们如何存储您的个人信息
      <br/>3.4.1存储地点
      <br/>3.4.1.1处理原则
      <br/>&emsp;&emsp;我们会按照法律法规规定，将中华人民共和国境内收集的用户个人信息存储于中国境内。
      <br/>3.4.1.2 跨境传输
      <br/>&emsp;&emsp;如果您的个人信息存储地点从中国境内转移到境外的，我们将严格依照法律的规定执行。
      <br/>&emsp;&emsp;需要将您的个人信息传输至境外的场景包括：
      <br/>（1）	获得您的明确授权；
      <br/>（2）	当营销场景涉及境外服务提供商时；
      <br/>（3）	当您通过随鹿旅行APP进行跨境交易（如预订境外酒店，预订境外机票，预订汽车票、火车票、船票、打车、旅游服务时涉及境外供应商提供服务时）等个人主动行为；
      <br/>（4）	法律法规的明确要求。
      <br/>&emsp;&emsp;除非符合征得授权同意的例外情形（见本政策“3.1我们如何收集和使用您的个人信息”），否则我们将会按照法律法规及国家标准的要求，针对除前述情形之外的个人信息跨境传输情形，采取合理的方式另外征得您的单独同意。但不论是否需要征得您的单独同意，我们均会以合理的方式向您明示告知所涉产品/服务的境内外服务商信息及其处理您个人信息的目的、方式和种类，例如，在您准备订购随鹿旅行APP的部分境外产品/服务时，我们将会在您下单前通过订单填写页面或订单确认页面向您明示告知上述信息，并在必要时就随鹿向境外供应商提供您的个人信息征得您的单独同意。具体的单独同意形式，以具体产品/服务订购页面为准。
      <br/>&emsp;&emsp;我们将按照法律、行政法规和国家网信主管部门规定（如履行安全评估义务、个人信息保护认证、按照主管部门制定的标准合同与境外接收方签订协议）条件执行，并要求境外机构对所获得的您的个人信息保密并履行相应的个人信息保护义务。
      <br/>3.4.2 存储期限
      <br/>&emsp;&emsp;我们仅在本政策所述目的所必需且最短的期间内，或法律法规要求的时限内保留您的信息。例如，当您使用随鹿旅行APP的注册功能时，我们需要收集您的手机号，且在您提供后并在您使用该功能期间，我们需要持续为您保留您的手机号，以向您正常提供该功能、保障您的账户和系统安全。在超出上述存储期限后，或在您行使个人信息删除权、注销账户的情况下，我们会对您的个人信息进行删除或匿名化处理。
      <br/>&emsp;&emsp;但在下列情况下，我们有可能因需符合法律要求，调整个人信息的存储时间：
      <br/>（1）	为遵守适用的法律法规等有关规定（例如：《电子商务法》规定：商品和服务信息、交易信息保存时间自交易完成之日起不少于三年）；
      <br/>（2）	为遵守法院判决、裁定或其他法律程序的规定；
      <br/>（3）	为遵守相关政府机关或法定授权组织的要求；
      <br/>（4）	为维护社会公共利益，为保护随鹿旅行APP的用户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益所合理必需的用途。

      <br/>3.4.3 停止运营
      <br/>&emsp;&emsp;如我们停止运营随鹿旅行APP产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。

      <br/>3.5 我们如何保护您的个人信息安全
      <br/>（1）	随鹿非常重视信息安全，成立了专门的负责团队，负责对个人信息处理活动以及采取的保护措施进行监督。我们努力为您提供信息保护，采取了合适的管理、技术以及物理安全措施。
      <br/>（2）	我们从数据的生命周期角度出发，在数据收集、存储、显示、处理、使用、销毁等各个环节建立了安全防护措施，根据信息敏感程度的级别采取不同的控制措施，包括但不限于访问控制、SSL加密传输、AES256bit或以上强度的加密算法进行加密存储、敏感信息脱敏显示等。
      <br/>（3）	我们对可能接触到您信息的员工也采取了严格管理，可监控他们的操作情况，对于数据访问、内外部传输使用、脱敏、解密等重要操作建立了审批机制，并与上述员工签署保密协议等。与此同时，我们还定期对员工进行信息安全培训，要求员工在日常工作中形成良好操作习惯，提升数据保护意识。
      <br/>（4）	尽管有前述的安全措施，但同时也请您理解在网络上不存在“完善的安全措施”。我们会按现有的技术提供相应的安全措施来保护您的信息，提供合理的安全保障，我们将尽力做到使您的信息不被泄露、损毁或丢失。
      <br/>（5）	您的账户均有安全保护功能，请妥善保管您用于登录随鹿旅行APP的设备以及您的账户名及密码信息，切勿将设备提供他人用于登录随鹿旅行APP或将密码告知他人，如果您发现自己的个人信息泄露，特别是您的账户名和密码发生泄漏，请您立即与我们的客服联系，以便我们采取相应的措施。
      <br/>（6）	请您及时保存或备份您的文字、图片等其他信息，您需理解并接受，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。
      <br/>（7）	在使用随鹿旅行APP服务进行网上交易时，请您妥善保护自己的个人信息（包括但不限于出行人姓名、联络方式或联系地址），仅在必要的情形下向他人提供。请使用复杂密码，协助我们保证您的账户安全。我们将尽力保障您发送给我们的任何信息的安全性。为防止他人未经授权使用您的密码或使用您的计算机、移动设备或SIM卡，如您发现自己的个人信息尤其是您的账户或密码发生泄漏，请您立即联络随鹿旅行APP客服，以便我们核实后根据您的申请采取相应措施。
      <br/>（8）	在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。

      <br/>3.6我们如何保护未成年人的个人信息
      <br/>（1）	随鹿非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的服务前，应事先取得您法定监护人的同意。我们根据《中华人民共和国未成年人保护法》等国家相关法律法规的要求对未成年人的个人信息及隐私进行保护。
      <br/>（2）	随鹿不会主动直接向未成年人收集其个人信息。对于经监护人同意而收集未成年人个人信息的情况，我们仅在法律法规允许、监护人同意或保护未成年人所必要的情况下使用、共享、转移或披露此类信息。
      <br/>（3）	作为监护人，您可以在随鹿旅行APP端登录后，通过“我的”－{'>'}“编辑信息”对未成人的相关信息进行查询、更正和删除操作。如果有事实证明未成年人并未取得监护人同意的情况下注册和使用了我们的服务，监护人可以联系随鹿旅行APP客服，我们会在确认后尽快删除相关未成年人的个人信息。
      <br/>（4）	对于不满14周岁的儿童个人信息，我们还会遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则，按照《儿童个人信息网络保护规定》等法律法规的要求进行收集、存储、使用、转移、披露等处理儿童个人信息。当您作为监护人为被监护的儿童选择使用随鹿旅行APP相关旅行服务时，我们可能需要向您收集被监护的儿童个人信息，用于向您履行相关服务之必要。如果您不提供前述信息，您将无法享受我们提供的相关服务。此外，您在使用评价功能时可能会主动向我们提供儿童个人信息，请您明确知悉并谨慎选择。您作为监护人应当正确履行监护职责，保护儿童个人信息安全。
      <br/>（5）	如果您是不满14周岁的儿童，在您使用随鹿旅行APP的服务前，您和您的监护人还应仔细阅读我们专门制定的《随鹿儿童个人信息保护规则及监护人须知》，确保您的监护人确认同意该规则。如果您的监护人不同意《随鹿儿童个人信息保护规则及监护人须知》，可能将导致我们无法向您正常提供服务或无法达到我们提供服务的预定效果，您和您的监护人应立即停止注册或使用我们提供的服务。如果您使用或继续使用我们的服务，将视为已获取监护人同意我们按照本政策和《随鹿儿童个人信息保护规则及监护人须知》收集、存储、使用、转移、披露等处理儿童的个人信息。
      <br/>（6）	如您对儿童个人信息相关事宜有任何意见、建议或投诉，请联系我们，具体联系方式详见本政策“3.9、如何联系我们”。

      <br/>3.7 您如何行使您的个人信息权利
      <br/>&emsp;&emsp;您对您的个人信息享有以下权利：
      <br/>3.7.1 个人信息的查询、更正、补充及管理
      <br/>&emsp;&emsp;在随鹿旅行APP端登录后，您可以按照下述步骤自行访问、更正、补充及管理您的个人信息：
      <br/>●	通过“我的”－{'>'}“编辑信息”，来访问、更正、补充及删除昵称、简介、性别、生日、地区、职业、学历、偏好等信息；
      <br/>●	通过“我的”－{'>'}“设置”{'>'}“账号与安全”，来访问、更正及补充您填写的真实姓名，进行实名认证；
      <br/>●	通过“我的”{'>'}“设置”{'>'}“账号与安全”，来绑定或解绑微信账号、邮箱及其他第三方账号；
      <br/>●	通过“我的”{'>'}“设置”{'>'}“隐私设置”，来访问、修改个人信息公开权限（包括主页显示内容权限等）
      <br/>●	通过“我的”{'>'}“设置”{'>'}“隐私设置”，来访问、修改个人应用访问权限（包括位置、相机、日历、文件存储、通讯录权限）
      <br/>●	通过“我的”{'>'}“设置”{'>'}“通用设置”来访问、修改您的浏览记录权限，删除您的浏览历史信息
      <br/>●	通过“行程”来访问及删除自己的旅行行程信息；
      <br/>●	通过点击“首页”最上方的搜索框，然后在下方的“历史搜索”中访问及删除历史搜索记录
      <br/>●	另外您还可以在APP端登录后通过“我的”{'>'}“设置”{'>'}“个人信息收集清单”来访问更多的个人信息。

      <br/>3.7.2 个人信息的删除
      <br/>&emsp;&emsp;您可以通过“3.7.1个人信息的查询、更正、补充及管理”列明的部分路径删除您的部分信息。
      <br/>&emsp;&emsp;同时，在下列情形中，您可以通过本政策“3.9”的联系方式与我们联系，向我们提出删除个人信息的请求，我们将在十五个工作日内回复您的请求：
      <br/>（1）	如果我们处理个人信息的行为违反法律法规；
      <br/>（2）	如果我们收集、使用您的个人信息，却未征得您的明确同意；
      <br/>（3）	如果我们处理个人信息的行为严重违反了与您的约定；
      <br/>（4）	如果您不再使用我们的产品或服务，或您主动注销了账户；
      <br/>（5）	如果我们永久不再为您提供产品或服务。
      <br/>&emsp;&emsp;当您或我们协助您删除相关信息后，因为适用的法律要求或安全技术原因，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其与任何进一步处理隔离，直至备份更新时可以清除这些信息或实现匿名。
      <br/>3.7.3 改变您授权同意的范围或撤回授权
      <br/>&emsp;&emsp;您可以通过删除您的个人信息、关闭设备功能等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。但请注意，每个业务功能需要一些基本的个人信息才能得以完成（见本政策“3.1我们如何收集和使用您的个人信息”），当您撤回授权后，我们无法继续为您提供所对应的服务。同时，基于部分产品的特殊性和相关法律法规的要求，如果您使用的服务尚未结束（例如，您出行类产品/服务过程尚未结束，或金融类产品尚有欠款未结清），在服务结束之前您有可能无法撤回您的授权。
      <br/>&emsp;&emsp;对于我们持续使用您的个人信息的数据处理行为，当您执行上述操作后，我们将不再处理相应的个人信息，但您的上述操作不会影响此前基于您的授权已经开展的个人信息处理。
      <br/>3.7.4 注销您的账户
      <br/>&emsp;&emsp;您可通过如下路径注销随鹿旅行APP账户：登录随鹿旅行APP进行账户注销，具体路径为：依次点击“我的”{'>'}“设置”－{'>'}“账号与安全”，然后点击“注销账号”。注销随鹿旅行APP账户后，您该账户内的所有信息将被清空，我们将不会再收集、使用或共享与该账户相关的个人信息，但之前的信息我们仍需按照监管要求的时间进行保存，且在该依法保存的时间内有权机关仍有权依法查询。在随鹿账户注销期间，如果您的账户涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查，随鹿有权自行终止账户的注销而无需另行得到您的同意。随鹿账户一旦被注销将不可恢复，请您在操作之前自行备份账户相关的所有信息和数据。注销随鹿账户，您将无法再使用本随鹿账户，也将无法找回您随鹿账户中及与账户相关的内容或信息。您理解并同意，随鹿无法协助您重新恢复前述服务。注销本随鹿账户并不代表本随鹿账户注销前的账户行为和相关责任得到豁免或减轻。
      <br/>3.7.5 获取个人信息副本
      <br/>&emsp;&emsp;如您需要您的个人信息的副本，您可以通过本政策“3.9”的联系方式与我们联系，在核实您的身份后，我们将向您提供您在我们的服务中的个人信息副本（包括基本资料、身份信息），但法律法规另有规定的或本政策另有约定的除外。
      <br/>3.7.6 个人信息的转移
      <br/>&emsp;&emsp;如您需要将您的个人信息转移至指定的个人信息处理者，您可以通过本政策第九条的联系方式与我们联系，在核实您的身份后，经审核符合国家网信部门规定条件的，我们将向您提供个人信息转移的途径，但法律法规另有规定的或本政策另有约定的除外。
      <br/>&emsp;&emsp;我们也将采取必要的信息管理措施与技术手段，确保转移过程的安全性，防止您的个人信息发生泄漏、损毁、丢失、篡改等后果。
      <br/>3.7.7 响应您的请求
      <br/>&emsp;&emsp;如果您无法通过上述方式访问、更正、补充或删除您的个人信息，或您需要访问、更正、补充或删除您在使用我们产品/服务时所产生的其他个人信息，或您需要获取您的个人信息副本或者转移您的个人信息，或您认为我们存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以通过本政策“3.9”的联系方式与我们联系。
      <br/>&emsp;&emsp;为了保障安全，我们可能需要您提供书面请求，或提供您的身份证明文件，我们将在收到您反馈并验证您的身份后的十五个工作日内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份服务器上存放的信息）的请求，我们可能会予以拒绝。
      <br/>&emsp;&emsp;在以下情形中，按照法律法规要求，我们将无法响应您的请求：
      <br/>（1）	与随鹿履行法律法规规定的义务相关的；
      <br/>（2）	与国家安全、国防安全有关的；
      <br/>（3）	与公共安全、公共卫生、重大公共利益有关的；
      <br/>（4）	与犯罪侦查、起诉和审判和执行判决等有关的；
      <br/>（5）	有充分证据表明您存在主观恶意或滥用权利的；
      <br/>（6）	出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；
      <br/>（7）	响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
      <br/>（8）	涉及商业秘密的。

      <br/>3.8 本政策的修订和通知
      <br/>&emsp;&emsp;我们会在必要时修改个人信息保护政策，请您理解，我们可能会适时修订本政策，我们将标注本政策最近更新的日期，并经公示后生效。未经您明确同意，我们不会削减您按照本政策所应享有的权利。对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件发送通知，说明个人信息保护政策的具体变更内容）。请您经常回访本政策，以阅读最新版本。
      <br/>&emsp;&emsp;本政策所指的重大变更包括但不限于：
      <br/>（1）	我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
      <br/>（2）	我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
      <br/>（3）	个人信息共享、转移或公开披露的主要对象发生变化；
      <br/>（4）	您参与个人信息处理方面的权利及其行使方式发生重大变化；
      <br/>（5）	我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
      <br/>（6）	个人信息安全影响评估报告表明存在高风险时。

      <br/>3.9 我们在何处搜集你的何种信息
      <br/>&emsp;&emsp;您使用随鹿平台的产品/服务过程中，我们会收集和使用您在访问或使用我们网站和客户端时的浏览记录、搜索记录、地理位置、设备信息（包括设备制造商、屏幕宽高、设备类型、设备识别符，IMEI、设备序列号、OAID、MEID、Android ID、IMSI、GUID、MAC地址、SIM卡序列号），已安装APP信息、传感器信息、剪切板、使用存储权限读取的外置存储信息以及其他取得您授权的信息，进行综合统计、以便了解您的喜好特征，从而向您提供更好的产品/服务，提升您的用户体验。


      <br/>（1）个人基本信息

      <br/>信息名称——收集场景——收集目的
      <br/>●	姓名——“我的”页——完善网络身份标识，支持支付场景的信息搜集
      <br/>●	生日——“我的”页——接收生日祝福
      <br/>●	头像——“我的”页——完善网络身份标识
      <br/>●	昵称——“我的”页——完善网络身份标识，增强算法推荐效果
      <br/>●	性别——“我的”页——完善网络身份标识，增强算法推荐效果
      <br/>●	手机号——“我的”页——用于创建账号、登录、找回账号密码，保护账号安全
      <br/>●	地区——“我的”页——完善网络身份标识，增强算法推荐效果
      <br/>●	邮箱——“我的”页——用于登录、找回账号密码，保护账号安全


      <br/>（2）身份证明信息
      <br/>&emsp;&emsp;身份证明信息用于实名认证，提高用户安全等级，包含：姓名、证件类别（包括身份证、军人身份证、护照、学生证、驾驶证、工作证等）、证件号码
      <br/>&emsp;&emsp;目前随鹿平台暂未收集此类数据，此后将进一步完善数据采集及合规工作，感谢理解。

      <br/>（3）常用个人及他人信息
      <br/>&emsp;&emsp;常用信息是您在随鹿平台上进行各类旅行产品消费时所需要填写的身份信息和联系方式，以及用以保障您成功出行进行一些验证和必要信息的通知，包括：常用旅客信息、常用订单联系人信息、常用收货地址信息等，可能涉及个人姓名、性别、证件号、手机号、通讯地址等信息。
      <br/>&emsp;&emsp;目前随鹿平台暂未收集此类数据，此后将进一步完善数据采集工作、持续升级产品服务，感谢理解。

      <br/>（4）用户行为信息

      <br/>信息名称——收集场景——收集目的
      <br/>●	页面访问及停留记录——启动 APP 且同意“用户协议”后——促进产品更新和提升用户体验
      <br/>●	日程规划、出行及增删记录——“趣定制”“专属行”“精选行程计划”“行程”等涉及日程规划的页面——提升算法效果和用户体验
      <br/>●	搜索/浏览记录——涉及城市、景点、日程计划等的搜索栏/页——方便用户回溯浏览记录
      <br/>●	订单及支付信息——目前随鹿平台暂未收集此类数据，此后将进一步完善数据采集工作、持续升级产品服务，感谢理解——方便用户管理订单和回溯支付记录

      <br/>（5）设备属性及定位信息
      <br/>信息名称——收集场景——收集目的
      <br/>●	位置——启动 APP 、同意“用户协议”且同意打开“获取地理位置”权限后——提升用户体验
      <br/>●	MAC——启动 APP 且同意“用户协议”后——标识设备、 保障账号及交易安全
      <br/>●	IMEI——启动 APP 且同意“用户协议”后——标识设备、 保障账号及交易安全
      <br/>●	IDFA——启动 APP 且同意“用户协议”后——标识设备、 广告归因
      <br/>●	IP——启动 APP 且同意“用户协议”后——判断是否为风险IP

      <br/>4 如何联系我们

      <br/>4.1如您有任何与本政策相关的问题、意见或建议，您可以发送邮件至我们的客服邮箱cs @soroute.cn
      <br/>4.2如您有任何与使用本产品产生的问题、意见或建议，您可以发送邮件至我们的投诉邮箱uf @soroute.cn
      <br/>4.3一般情况下，我们将在十五个工作日内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信办、消费者保护协会等监管部门进行投诉，投诉方式如下：
      <br/>（1）国家网信办举报中心：www.12377.cn
      <br/>（2）消费者投诉举报电话：12315
      <br/>4.4或者您还可以通过向浙江省杭州市西湖区人民法院提起诉讼以解决争议。
      <br/>4.5如果您有任何商务合作需求，请发送邮件至相关邮箱：
      <br/>（1）品牌及广告商务：adv @soroute.cn
      <br/>（2）旅行产品商城商务：tp @soroute.cn
      <br/>（3）酒店及OTA合作：ota @soroute.cn
      <br/>5 如果您希望加入团队，我们诚挚地邀请您将信息发送至以下联系方式：

      <br/>&emsp;&emsp;hr @soroute.cn

    </div>
  );
}

export default App;
